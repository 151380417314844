<template>
    <lassoLayout class="page-login">
        <div class="page_form_layout full_page">
            <v-container>
                <div class="wrap_form_layout">
                    <div class="form-card">
                        <h2 class="join_the_network">New password</h2>
                        <div class="form">
                            <form action="#" class="form">
                                <div class="password">
                                    <div>
                                        <label for="">New Password</label>
                                        <button type="button" class="show_password" @click="switchVisibilityNewPasswordType('password')"
                                                v-html="newPasswordType === 'password'? 'Show' : 'Hide'"></button>
                                    </div>
                                    <input :type="newPasswordType" v-model="newPassword" @keyup="passwordOnKeyDown" tabindex="1">
                                </div>
                                <div class="password">
                                    <div>
                                        <label for="">Confirm Password</label>
                                        <button type="button" class="show_password" @click="switchVisibilityConfirmPassword('password')"
                                                v-html="confirmPasswordType === 'password'? 'Show' : 'Hide'"></button>
                                    </div>
                                    <input :type="confirmPasswordType" v-model="confirmPassword" @keyup="passwordOnKeyDown" tabindex="1">
                                </div>
                            </form>
                        </div>
                        <div class="password_complexity">
                            <span class="label">Password must include:</span>
                            <div class="wrap_must_symbols">
                                <div :class="[ {active: passComplexity.upperCase}, 'item_' ]">A-Z</div>
                                <div :class="[ {active: passComplexity.lowerCase}, 'item_']">a-z</div>
                                <div :class="[ {active: passComplexity.numbers}, 'item_']">123</div>
                                <div :class="[ {active: passComplexity.nonWordChars}, 'item_']">!@#</div>
                                <div :class="[ {active: newPassword.length >= 8}, 'item_']">8+</div>
                                <div :class="[ {active: confirmPassword && newPassword === confirmPassword}, 'item_']">Match</div>
                            </div>
                        </div>
                        <div class="message_error">
                            <template v-if="RESET_PASSWORD_STATUS.success === false">
                                {{RESET_PASSWORD_STATUS.message}}
                            </template>
                        </div>

                        <button v-if="passwordValid === true && $v.confirmPassword.$invalid === false"
                                @click="saveNewPassword"
                                type="button"
                                class="sign_up c2a_btn"
                                v-ripple
                                :class="{'loading': loader}">
                            Save
                            <v-progress-circular :width="2" indeterminate></v-progress-circular>
                            <span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
                        </button>
                        <button v-else type="button" class="sign_up c2a_btn" disabled>Save</button>
                    </div>
                </div>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {minLength, maxLength, required, sameAs} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
    name: "password-recovery",
    components: {
        lassoLayout
    },
    data() {
        return {
            loader: false,
            newPassword: '',
            confirmPassword: '',
            newPasswordType: 'password',
            confirmPasswordType: 'password',
            passComplexity: {
                lowerCase: false,
                upperCase: false,
                nonWordChars: false,
                numbers: false
            },
            passwordValid: false,
        }
    },
    props: ['token'],

    // Validations
    validations: {
        newPassword: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(16)
        },
        confirmPassword: {
            required,
            sameAsPassword: sameAs('newPassword')
        }
    },
    computed: {
        ...mapGetters(['RESET_PASSWORD_STATUS']),
    },
	created() {
		console.log('password-recovery, SET_NEED_LOGIN_REDIRECT_PATH');
		this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', '');
		this.$store.dispatch('LOGOUT').then(() => {
		});
	},
	methods: {
        ...mapActions(['RESET_PASSWORD']),
        passwordOnKeyDown() {
            this.passComplexity.nonWordChars = !!this.newPassword.match(/[!@#$%^&*().,;:{}_+=<>?|~-]/);
            this.passComplexity.upperCase = !!this.newPassword.match(".*[A-Z]");
            this.passComplexity.lowerCase = !!this.newPassword.match(".*[a-z]");
            this.passComplexity.numbers = !!this.newPassword.match(/[0-9]/);

            this.passwordValid = this.passComplexity.lowerCase === true && 
                               this.passComplexity.upperCase === true && 
                               this.passComplexity.nonWordChars === true &&
                               this.passComplexity.numbers === true;
        },
        switchVisibilityNewPasswordType() {
            this.newPasswordType = this.newPasswordType === 'password' ? 'text' : 'password';
        },
        switchVisibilityConfirmPassword() {
            this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
        },
        saveNewPassword() {
            if (this.token && (this.newPassword === this.confirmPassword)) {
                const params = {
                    token: this.token,
                    password: this.newPassword,
                }
                this.RESET_PASSWORD(params)
                    .then(() => {
                        if (this.RESET_PASSWORD_STATUS.success === true) {
							console.log('RESET_PASSWORD, redirect to login');
                            this.$router.push({name: 'login'});
                        }
                    })
                    .catch(err => console.log(`saveNewPassword, ${err}`));
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/components/_forgotPassword';
</style>
